var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-button-group',[_c('b-button',{staticClass:"btn-sm btn-icon mr-1 rounded",attrs:{"variant":"secondary","title":"Ver PDF"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('PDFAction', _vm.data.item.id, 'view')}}},[_c('feather-icon',{attrs:{"icon":"PrinterIcon"}})],1),_c('b-button',{staticClass:"btn-sm btn-icon mr-1 rounded",attrs:{"variant":"secondary","title":"Descargar PDF"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('PDFAction', _vm.data.item.id, 'download')}}},[_c('feather-icon',{attrs:{"icon":"DownloadIcon"}})],1),_c('b-button',{staticClass:"btn-sm btn-icon mr-1 rounded",attrs:{"variant":"secondary","title":_vm.$can('update', _vm.modulo)
      ? _vm.userEdit
        ? 'Editar'
        : 'No tienes permisos para editar OCs en estado Aprobado'
      : 'No tienes permisos para editar OCs',"disabled":!_vm.userEdit || !_vm.$can('update', _vm.modulo)},on:{"click":function($event){_vm.$can('update', _vm.modulo)
      ? _vm.userEdit
        ? _vm.$emit('processGoToUpdate', _vm.data.item)
        : ''
      : ''}}},[_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1),_c('b-button',{staticClass:"btn-sm btn-icon rounded",attrs:{"variant":"secondary","title":_vm.$can('delete', _vm.modulo)
      ? 'Eliminar'
      : 'No tienes permisos para eliminar OCs',"disabled":!_vm.$can('delete', _vm.modulo)},on:{"click":function($event){_vm.$can('delete', _vm.modulo)
      ? _vm.$emit('processRemove', _vm.data.item.id)
      : ''}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }