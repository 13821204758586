<template>
  <b-button-group>
    <b-button
      variant="secondary"
      class="btn-sm btn-icon mr-1 rounded"
      title="Ver PDF"
      @click.prevent="$emit('PDFAction', data.item.id, 'view')"
    >
      <feather-icon
        icon="PrinterIcon"
      />
    </b-button>
    <b-button
      variant="secondary"
      class="btn-sm btn-icon mr-1 rounded"
      title="Descargar PDF"
      @click.prevent="$emit('PDFAction', data.item.id, 'download')"
    >
      <feather-icon
        icon="DownloadIcon"
      />
    </b-button>

    <b-button
      variant="secondary"
      class="btn-sm btn-icon mr-1 rounded"
      :title="$can('update', modulo)
        ? userEdit
          ? 'Editar'
          : 'No tienes permisos para editar OCs en estado Aprobado'
        : 'No tienes permisos para editar OCs'"
      @click="$can('update', modulo)
        ? userEdit
          ? $emit('processGoToUpdate', data.item)
          : ''
        : ''"
      :disabled="!userEdit || !$can('update', modulo)"

    >
      <feather-icon
        icon="Edit2Icon"
      />
    </b-button>

    <b-button
      variant="secondary"
      class="btn-sm btn-icon rounded"
      :title="$can('delete', modulo)
        ? 'Eliminar'
        : 'No tienes permisos para eliminar OCs'"
      @click="$can('delete', modulo)
        ? $emit('processRemove', data.item.id)
        : ''"
      :disabled="!$can('delete', modulo)"
    >
      <feather-icon
        icon="TrashIcon"
      />
    </b-button>
  </b-button-group>
</template>

<script>
import {
  BButton, BButtonGroup,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BButton,
    BButtonGroup,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    modulo: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({ getUser: 'auth/user' }),
    userEdit() {
      return this.permisosEditar()
    },
  },
  methods: {
    permisosEditar() {
      const { estado } = this.data.item
      const { tipoUsuario } = this.getUser

      let opcion = true
      if (estado === 'Aprobado' && tipoUsuario !== 'Admin') {
        opcion = false
      }
      return opcion
    },
  },
}
</script>
